import Head from "next/head";
import styles from "../styles/Home.module.css";
import { useState, useEffect, useRef } from "react";

import { resources } from "../i18n";

export default function Home() {
  const [currentLang, setCurrentLang] = useState("en");
  const [, forceUpdate] = useState();

  const addZero = (i) => {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  };

  const t = (key) => {
    return resources[currentLang].translation[key];
  };

  const e = (key) => {
    if (key === undefined || key === null) {
      return;
    }

    let errorValue = resources[currentLang].translation[key.props.children[1]];

    return errorValue;
  };

  return (
    <div className={styles.container}>
      <Head>
        <title>{t("Klarity")}</title>
        <link rel="icon" href="/favicon.jog" />
      </Head>

      <footer className={styles.footer}>
        <div>
          <a
            className="link2"
            href="https://www.getklarity.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Powered by Klarity")}
          </a>
        </div>

        <div></div>
      </footer>
    </div>
  );
}
